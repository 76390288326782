import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getEntityItems, readEntities } from '../../redux/api';
import { loadSession } from '../../redux/reducers/global-catalog.reducer';
import Button from '../../shared/controls/button';
import { View, ViewTitle } from '../../shared/styled/view';
import SVGIcon, { icons } from '../../shared/svg-icon';
import moment from 'moment';

// const ORBI_SESSION = "orbinetwork_session";

class HomeDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this.props.loadHomeInfo({
      company_id: this.props.companyId
    });
    this.props.loadSubscriptionCatalog();
    this.props.loadWalletInfo({
      company_id: this.props.companyId
    });
  }

  getExpirationDate = () => {
  
      if (this.props.walletInfo && this.props.walletInfo.expiration_date) {
  
        let now = moment();
        let expiration_date = moment(this.props.walletInfo.expiration_date);
  
        if (expiration_date > now) {
          return(
            <div className="date">{`Vigente hasta ${expiration_date.format('DD/MMMM/YYYY')}`}</div>
          );
        } else {
          return <div className="date"></div>;
        }
      }
  
      return <div className="date"></div>;
  
    }

  render() {
    // if(this.props.company?.type?.id === 2){
      return (
        <View className="home-dashboard">
          <ViewTitle>
            Configuración
            <div className="subscription-info">
              <div>
                Plan Actual
              </div>
              <div className="current-subscription">
                <div>
                  { this.props.planInfo !== null ? this.props.planInfo.description : '' }
                </div>
                {
                  this.getExpirationDate()
                }
              </div>
              <div className="actions">
                <Button 
                  type="primary"
                  text="Ir a mis suscripciones"
                  onClick={() =>
                    this.props.history.push(
                      `/company/payments`
                  )}
                />
              </div>
            </div>
          </ViewTitle>
          <div className="content">
            <div className="panels">
              <div className="upper">
                {
                  this.props.company?.type?.id === 2 
                  ? <>
                    <div style={{width: "20%"}} className={"panel".concat(this.props.homeInfo?.company?.tags?.products ? "" : " blurred")}>
                      {
                        this.props.homeInfo?.company?.tags?.products
                        ? ""
                        : <div className="overlay">
                          Desbloquea agregando etiquetas de producto.
                        </div>
                      }
                      <div className="panel-title">
                        <div style={{display: 'flex'}}>
                          <img src="/images/box_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                          TOTAL DE PRODUCTOS  
                        </div>
                      </div>
                      <div className="panel-body">
                        <div className="qty main">
                          { this.props.homeInfo?.company?.products_catalog?.total || "-"}
                        </div>
                        <div className="panel-actions">
                          <Button 
                            text={"Agregar producto"}
                            type="primary"
                            onClick={() => {
                              this.props.history.push(`/company/product/`)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="panel" style={{width: "70%"}}>
                      <div className="panel-title" style={{marginBottom: 10}}>
                        <div style={{display: 'flex'}}>
                          <img src="/images/company_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                          MI EMPRESA
                        </div>
                      </div>
                      <div className="panel-body summary-container">
                        <div className="summary" style={{width: "auto", minWidth: "50%"}}>
                          <div className="summary-title">
                            <div style={{display: 'flex'}}>
                              <img src="/images/tag_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                              Grupos (Etiquetas)
                            </div>
                            {
                              (
                                this.props.homeInfo?.company?.tags?.class ||
                                this.props.homeInfo?.company?.tags?.routes ||
                                this.props.homeInfo?.company?.tags?.trips ||
                                this.props.homeInfo?.company?.tags?.orders ||
                                this.props.homeInfo?.company?.tags?.facilities ||
                                this.props.homeInfo?.company?.tags?.products
                              ) ?
                              <div className="link-container">
                                <Link to={`/company/profile/${this.props.company.orbi_id}`}>
                                  <span className="link">Ver etiquetas</span>
                                </Link>
                              </div>
                              : ""
                            }
                          </div>
                          <div className="summary-content">
                            {
                              (
                                this.props.homeInfo?.company?.tags?.class ||
                                this.props.homeInfo?.company?.tags?.routes ||
                                this.props.homeInfo?.company?.tags?.trips ||
                                this.props.homeInfo?.company?.tags?.orders ||
                                this.props.homeInfo?.company?.tags?.facilities ||
                                this.props.homeInfo?.company?.tags?.products
                              )
                              ?
                              <>
                                <div className="summary-item">
                                  <div className="description">
                                    Producto
                                  </div>
                                  <div className="value">
                                    {this.props.homeInfo?.company?.tags?.products || '0'}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="description">
                                    Instalación
                                  </div>
                                  <div className="value">
                                    {this.props.homeInfo?.company?.tags?.facilities || '0'}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="description">
                                    Órdenes
                                  </div>
                                  <div className="value">
                                    {this.props.homeInfo?.company?.tags?.orders || '0'}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="description">
                                    Viajes
                                  </div>
                                  <div className="value">
                                    {this.props.homeInfo?.company?.tags?.trips || '0'}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="description">
                                    Rutas
                                  </div>
                                  <div className="value">
                                    {this.props.homeInfo?.company?.tags?.routes || '0'}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="description">
                                    Expedición
                                  </div>
                                  <div className="value">
                                    {this.props.homeInfo?.company?.tags?.class || '0'}
                                  </div>
                                </div>
                              </>
                              :
                              <Button 
                                text="Crear etiquetas"
                                type="primary"
                                onClick={() =>
                                  this.props.history.push(
                                    `/company/profile/${this.props.company.orbi_id}`
                                )}
                              />
                            }
                          </div>
                        </div>
                        <div className="summary">
                          <div className="summary-title">
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <img src="/images/user2_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                              Roles de usuarios
                            </div>
                          </div>
                          <div className="qty medium">
                            {
                              this.props.homeInfo?.company?.roles?.total 
                              || <Button 
                                text="Crear roles"
                                type="primary"
                                onClick={() =>
                                  this.props.history.push(
                                    `/company/profile/${this.props.company.orbi_id}?tab=permisos`
                                )}
                              />
                            }
                          </div>
                          <div className="link-container">
                            {
                              this.props.homeInfo?.company?.roles?.total
                              ? <Link to={`/company/profile/${this.props.company.orbi_id}?tab=permisos`}>
                                  <span className="link">Ver permisos</span>
                                </Link>
                              : ""
                            }
                          </div>
                        </div>
                        <div className="summary">
                          <div className="summary-title">
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <img src="/images/truck_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                              Clases de transportes
                            </div>
                          </div>
                          <div className="qty medium">
                            {
                              this.props.homeInfo?.company?.transport_classes?.total || 
                              <Button 
                                text="Crear clases"
                                type="primary"
                                onClick={() =>
                                  this.props.history.push(
                                    `/company/profile/${this.props.company.orbi_id}?tab=clases`
                                )}
                              />
                            }
                          </div>
                          <div className="link-container">
                            {
                              this.props.homeInfo?.company?.transport_classes?.total 
                              ? <Link to={`/company/profile/${this.props.company.orbi_id}?tab=clases`}>
                                  <span className="link">Ver clases</span>
                                </Link>
                              : ""
                            }
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    </>
                  : <div className={"panel".concat(this.props.company?.type?.id === 1 ? "" : " blurred")}>
                      <div className="panel-title">
                        <div style={{display: 'flex'}}>
                          <img src="/images/truck2_icon.svg" alt="" className="icono" style={{marginRight: 8, transform: 'scaleX(-1)'}}/>
                          FLOTILLA PROPIA
                        </div>
                        <div className="link-container">
                          <Link to={`/company/fleet/summary`}>
                            <span className="link">Ir a flotillas</span>
                          </Link>
                        </div>
                      </div>
                      <div className="panel-body">
                        <div className="summary">
                          <div className="summary-content">
                            <div className="summary-item">
                              <div className="summary-title">
                                <div style={{display: 'flex'}}>
                                  <img src="/images/user2_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                                  Operadores
                                </div>
                              </div>
                              <div className="value">
                                {this.props.homeInfo?.fleet?.operators?.total || 0}
                              </div>
                            </div>
                            {/* <div className="divisor"></div> */}
                            <div className="summary-item">
                              <div className="summary-title">
                                <div style={{display: 'flex'}}>
                                  <img src="/images/truck_icon.svg" alt="" className="icono" style={{marginRight: 8, transform: 'scaleX(-1)'}}/>
                                  Vehículos
                                </div>
                              </div>
                              <div className="value">
                                {this.props.homeInfo?.fleet?.vehicles?.total || 0}
                              </div>
                            </div>
                            {/* <div className="divisor"></div> */}
                            <div className="summary-item">
                              <div className="summary-title">
                                <div style={{display: 'flex'}}>
                                  <SVGIcon name={icons.equipment} fill='#AFC8FF' width='23px' height='23px'/>
                                  <div style={{ marginRight: 8 }}/>
                                  Remolques
                                </div>
                              </div>
                              <div className="value">
                                {this.props.homeInfo?.fleet?.equipments?.total || 0}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="complimentary-info">
                          <div className="summary-item">
                            <div className="description">
                              Completos
                            </div>
                            <div className="value">
                              {this.props.homeInfo?.fleet?.operators?.complete || 0}
                            </div>
                          </div>
                          <div className="summary-item">
                            <div className="description">
                              Incompletos
                            </div>
                            <div className="value">
                              {this.props.homeInfo?.fleet?.operators?.incomplete || 0}
                            </div>
                          </div>
                          <div className="summary-item">
                            <div className="description">
                              Completos
                            </div>
                            <div className="value">
                              {this.props.homeInfo?.fleet?.vehicles?.complete || 0}
                            </div>
                          </div>
                          <div className="summary-item">
                            <div className="description">
                              Incompletos
                            </div>
                            <div className="value">
                              {this.props.homeInfo?.fleet?.vehicles?.incomplete || 0}
                            </div>
                          </div>
                          <div className="summary-item">
                            <div className="description">
                              Completos
                            </div>
                            <div className="value">
                              {this.props.homeInfo?.fleet?.equipments?.complete || 0}
                            </div>
                          </div>
                          <div className="summary-item">
                            <div className="description">
                              Incompletos
                            </div>
                            <div className="value">
                              {this.props.homeInfo?.fleet?.equipments?.incomplete || 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                }
                
              </div>
              <div className="lower">
                <div className={"panel".concat(this.props.homeInfo?.facilities?.summary?.total || this.props.company?.type?.id === 1 ? "" : " blurred")}>
                  {
                    (this.props.homeInfo?.facilities?.summary?.total || this.props.company?.type?.id === 1)
                    ? ""
                    : <div className="overlay">
                      Desbloquea agregando instalaciones.
                    </div>
                  }
                  <div className="panel-title">
                    <div style={{display: 'flex'}}>
                      <img src="/images/network_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                      MI RED
                    </div>
                    <div className="extra-info">
                      TOTAL : {this.props.homeInfo?.network?.summary?.connected || '0'}
                    </div>
                  </div>
                  <div className="panel-body">
                    <div className="summary">
                      <div className="summary-content">
                        {
                          (
                            this.props.homeInfo?.network?.company_descriptions["Dueño de Carga"] ||
                            this.props.homeInfo?.network?.company_descriptions["Transportista"] ||
                            this.props.homeInfo?.network?.company_descriptions["Ferrocarriles"] ||
                            this.props.homeInfo?.network?.company_descriptions["Aerolínea"] ||
                            this.props.homeInfo?.network?.company_descriptions["Naviera"] ||
                            this.props.homeInfo?.network?.company_descriptions["Orbidelivery repartidores independientes"] ||
                            this.props.homeInfo?.network?.company_descriptions["Orbipeople"] ||
                            this.props.homeInfo?.network?.company_descriptions["Patio de contenedores"] ||
                            this.props.homeInfo?.network?.company_descriptions["Terminal Aérea"] ||
                            this.props.homeInfo?.network?.company_descriptions["Terminal ferroviaria"] ||
                            this.props.homeInfo?.network?.company_descriptions["Terminal marítima"] ||
                            this.props.homeInfo?.network?.company_descriptions["Warehouser"]
                          )
                          ? <>
                              <div className="summary-item">
                                <div className="description">
                                  D. carga
                                </div>
                                <div className="value">
                                  {this.props.homeInfo?.network?.company_descriptions["Dueño de Carga"] || '0'}
                                </div>
                              </div>
                              <div className="summary-item">
                                <div className="description">
                                  Transportista
                                </div>
                                <div className="value">
                                  {this.props.homeInfo?.network?.company_descriptions["Transportista"] || '0'}
                                </div>
                              </div>
                              <div className="summary-item">
                                <div className="description">
                                  Ferrocarril
                                </div>
                                <div className="value">
                                  {this.props.homeInfo?.network?.company_descriptions["Ferrocarriles"] || '0'}
                                </div>
                              </div>
                              <div className="summary-item">
                                <div className="description">
                                  Otros
                                </div>
                                <div className="value">
                                  {(this.props.homeInfo?.network?.company_descriptions["Aerolínea"] || 0)
                                  + (this.props.homeInfo?.network?.company_descriptions["Naviera"] || 0)
                                  + (this.props.homeInfo?.network?.company_descriptions["Orbidelivery repartidores independientes"] || 0)
                                  + (this.props.homeInfo?.network?.company_descriptions["Orbipeople"] || 0)
                                  + (this.props.homeInfo?.network?.company_descriptions["Patio de contenedores"] || 0)
                                  + (this.props.homeInfo?.network?.company_descriptions["Terminal Aérea"] || 0)
                                  + (this.props.homeInfo?.network?.company_descriptions["Terminal ferroviaria"] || 0)
                                  + (this.props.homeInfo?.network?.company_descriptions["Terminal marítima"] || 0)
                                  + (this.props.homeInfo?.network?.company_descriptions["Warehouser"] || 0)}
                                </div>
                              </div>
                            </>
                          : <Button 
                              text="Invitar empresas"
                              type="primary"
                              onClick={() =>
                                this.props.history.push(
                                  `/company/network`
                              )}
                            />
                        }
                      </div>
                      <div className="link-container">
                        {
                          (
                            this.props.homeInfo?.network?.company_descriptions["Dueño de Carga"] ||
                            this.props.homeInfo?.network?.company_descriptions["Transportista"] ||
                            this.props.homeInfo?.network?.company_descriptions["Ferrocarriles"] ||
                            this.props.homeInfo?.network?.company_descriptions["Aerolínea"] ||
                            this.props.homeInfo?.network?.company_descriptions["Naviera"] ||
                            this.props.homeInfo?.network?.company_descriptions["Orbidelivery repartidores independientes"] ||
                            this.props.homeInfo?.network?.company_descriptions["Orbipeople"] ||
                            this.props.homeInfo?.network?.company_descriptions["Patio de contenedores"] ||
                            this.props.homeInfo?.network?.company_descriptions["Terminal Aérea"] ||
                            this.props.homeInfo?.network?.company_descriptions["Terminal ferroviaria"] ||
                            this.props.homeInfo?.network?.company_descriptions["Terminal marítima"] ||
                            this.props.homeInfo?.network?.company_descriptions["Warehouser"]
                          )
                          ? 
                              <Link to={`/company/network`}>
                                <span className="link">Ver red</span>
                              </Link>
                            
                          : ""
                        }
                      </div>
                    </div>
                    <div className="complimentary-info">
                      <div className="summary-item">
                        <div className="description" style={{ width: 105 }}>
                          <div style={{display: 'flex'}}>
                            <img src="/images/received_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                            Solicitudes Recibidas
                          </div>
                        </div>
                        <div className="value">
                          {this.props.homeInfo?.network?.requests?.received || '0'}
                        </div>
                      </div>
                      <div className="summary-item">
                        <div className="description" style={{ width: 105 }}>
                          <div style={{display: 'flex'}}>
                            <img src="/images/send_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                            Solicitudes Enviadas
                          </div>
                        </div>
                        <div className="value">
                          {this.props.homeInfo?.network?.requests?.sent || '0'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"panel".concat(this.props.homeInfo?.company?.roles?.total || this.props.company?.type?.id === 1 ? "" : " blurred")}>
                  {
                    this.props.homeInfo?.company?.roles?.total || this.props.company?.type?.id === 1
                    ? ""
                    : <div className="overlay">
                      Desbloquea agregando roles de usuario.
                    </div>
                  }
                  <div className="panel-title">
                    <div style={{display: 'flex'}}>
                      <img src="/images/user_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                      MIS USUARIOS
                    </div>
                    <div className="extra-info">
                      TOTAL : {this.props.homeInfo?.users?.summary?.total || 0}
                    </div>
                  </div>
                  <div className="panel-body">
                    <div className="summary">
                      <div className="summary-content">
                        {
                          (
                            this.props.homeInfo?.users?.summary?.admins ||
                            this.props.homeInfo?.users?.summary?.warehouser
                          )
                          ? <>
                              <div className="summary-item">
                                <div className="description">
                                  Owner
                                </div>
                                <div className="value">
                                  {this.props.homeInfo?.users?.summary?.owner || 0}
                                </div>
                              </div>
                              <div className="summary-item">
                                <div className="description">
                                  Admin
                                </div>
                                <div className="value">
                                  {this.props.homeInfo?.users?.summary?.admins || 0}
                                </div>
                              </div>
                              <div className="summary-item">
                                <div className="description">
                                  Warehouser
                                </div>
                                <div className="value">
                                  {this.props.homeInfo?.users?.summary?.warehouser || 0}
                                </div>
                              </div>
                            </>
                          : <Button 
                              text="Invitar usuarios"
                              type="primary"
                              onClick={() =>
                                this.props.history.push(
                                  `/company/user`
                              )}
                            />
                        }
                        
                      </div>
                      <div className="link-container">
                        {
                          (
                            this.props.homeInfo?.users?.summary?.admins ||
                            this.props.homeInfo?.users?.summary?.warehouser
                          )
                          ? <Link to={`/company/user`}>
                              <span className="link">Ver usuarios</span>
                            </Link>
                          : ""
                        }
                      </div>
                    </div>
                    <div className="complimentary-info">
                      <div className="summary-item">
                        <div className="description" style={{ width: 105 }}>
                          <div style={{display: 'flex'}}>
                            <img src="/images/received_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                            Solicitudes Recibidas
                          </div>
                        </div>
                        <div className="value">
                          {this.props.homeInfo?.users?.invitations?.received || 0}
                        </div>
                      </div>
                      <div className="summary-item">
                        <div className="description" style={{ width: 105 }}>
                          <div style={{display: 'flex'}}>
                            <img src="/images/send_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                            Invitaciones Enviadas
                          </div>
                        </div>
                        <div className="value">
                          {this.props.homeInfo?.users?.invitations?.sent || 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  this.props.company?.type?.id === 2 &&
                    <div className={"panel".concat(this.props.homeInfo?.company?.tags?.facilities ? "" : " blurred")}>
                      {
                        this.props.homeInfo?.company?.tags?.facilities
                        ? ""
                        : <div className="overlay">
                          Desbloquea agregando etiquetas de intalación.
                        </div>
                      }
                      <div className="panel-title">
                        <div style={{display: 'flex'}}>
                          <img src="/images/factory_icon.svg" alt="" className="icono" style={{marginRight: 8}}/>
                          MIS INSTALACIONES
                        </div>
                        <div className="extra-info">
                          TOTAL : {this.props.homeInfo?.facilities?.summary?.total || 0}
                        </div>
                      </div>
                      <div className="panel-body">
                        <div className="summary">
                          <div className="summary-content">
                            {
                              (
                                this.props.homeInfo?.facilities?.categories?.factory ||
                                this.props.homeInfo?.facilities?.categories?.warehouse ||
                                this.props.homeInfo?.facilities?.categories?.store
                              )
                              ? <>
                                  <div className="summary-item">
                                    <div className="description">
                                      Fábricas
                                    </div>
                                    <div className="value">
                                      {this.props.homeInfo?.facilities?.categories?.factory || 0}
                                    </div>
                                  </div>
                                  <div className="summary-item">
                                    <div className="description">
                                      Centro de distribución
                                    </div>
                                    <div className="value">
                                      {this.props.homeInfo?.facilities?.categories?.warehouse || 0}
                                    </div>
                                  </div>
                                  <div className="summary-item">
                                    <div className="description">
                                      Tiendas
                                    </div>
                                    <div className="value">
                                      {this.props.homeInfo?.facilities?.categories?.store || 0}
                                    </div>
                                  </div>
                                  <div className="summary-item">
                                    <div className="description">
                                      Otras
                                    </div>
                                    <div className="value">
                                      {this.props.homeInfo?.facilities?.categories?.other || 0}
                                    </div>
                                  </div>
                                </>
                              : <Button 
                                  text="Crear instalaciones"
                                  type="primary"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/company/facility/create`
                                  )}
                                />
                            }
                            
                          </div>
                          <div className="link-container">
                            {
                              (
                                this.props.homeInfo?.facilities?.categories?.factory ||
                                this.props.homeInfo?.facilities?.categories?.warehouse ||
                                this.props.homeInfo?.facilities?.categories?.store
                              )
                              ? <Link to={`/company/facility`}>
                                  <span className="link">Ir a instalaciones</span>
                                </Link>
                              : ""
                            }
                            
                          </div>
                        </div>
                        <div className="complimentary-info">
                          <div className="summary">
                            <div className="summary-content">
                              <div className="summary-item">
                                <div className="description">
                                  Completas
                                </div>
                                <div className="value">
                                  {this.props.homeInfo?.facilities?.summary?.complete || 0}
                                </div>
                              </div>
                              <div className="summary-item">
                                <div className="description">
                                  Incompletas
                                </div>
                                <div className="value">
                                  {this.props.homeInfo?.facilities?.summary?.incomplete || 0}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                }
              </div>
            </div>
            <div className="orders">

            </div>
          </div>
        </View>
      )
    // } else {
    //   return <div></div>
    // }
  }
}

const mapStateToProps = (state) => {
  // console.log("ESTADO",state);
  const company = state.globalCatalog.session.company;
  const companyId = state.globalCatalog.session.company.id;
  const homeInfo = getEntityItems(state, "COMPANIES.HOME");
  const walletInfo = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.WALLET"); 
  const subscriptionCatalog = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.CATALOGSUBSCRIPTION") || [];

  let planInfo = subscriptionCatalog.filter( item => {
    return (walletInfo && walletInfo.length > 0 && walletInfo[0].subscription === item.id)
  });

  return {
    planInfo: planInfo.length > 0 ? planInfo[0] : null,
    walletInfo: walletInfo.length > 0 ? walletInfo[0] : null,
    company,
    companyId,
    homeInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    loadHomeInfo: (params, opt) =>
      dispatch(readEntities("COMPANIES.HOME", params, opt)),
    loadSubscriptionCatalog: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.CATALOGSUBSCRIPTION", params, opt)),
    loadWalletInfo: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.WALLET", params, opt))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeDashboard);

//const HOMEINFO = {"id":511,"network":{"company_id":61,"summary":{"connected":50,"rejected":0,"pending":2},"requests":{"total":52,"sended":1,"received":51},"company_descriptions":{"Transportista":50,"Dueño de Carga":0,"Warehouser":0,"Naviera":0,"Terminal marítima":0,"Patio de contenedores":0,"Ferrocarriles":0,"Terminal ferroviaria":0,"Aerolínea":0,"Terminal Aérea":0,"Orbidelivery repartidores independientes":0,"Orbipeople":0}},"users":{"company_id":61,"summary":{"total":66,"owner":1,"admins":63,"warehouser":2},"invitations":{"total":206,"sended":204,"received":2}},"facilities":{"company_id":61,"summary":{"total":249,"complete":229,"incomplete":20},"categories":{"store":1,"factory":245,"warehouse":3}},"company":{"company_id":61,"tags":{"total":60,"rol":0,"facilities":44,"fleet":0,"routes":4,"products":0,"orders":0,"operations":2,"class":10,"trips":0},"roles":{"total":21},"transport_classes":{"total":15}}}