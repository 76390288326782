import React from "react";
import {
  Container,
  MainHeader,
  MainContainer,
  LeftColumns,
  RightColumns,
} from "./style";
import Button from "../../shared/controls/button";
import PaymentsPurchase from "./dashboard/purchase";
import PaymentsAccount from "./dashboard/account";
import PaymentsHistory from "./dashboard/history";
import { connect } from "react-redux";
import { getEntityItems, partialEntity, readEntities, readEntity } from "../../redux/api";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import { SERVER } from "../../redux/api/api-urls";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import GenericDialog from "../../shared/dialog/generic-modal";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import Loader from "../../shared/icons/tat/loader";
import styled from "styled-components";

const FIRST_YEAR = 2021;
const TODAY = new Date();

class PaymentsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credits: 10560,
      selectedYear: FIRST_YEAR,
      verification_status: null,
      errorMsg: '',
      cancelOrderMessage: '',
      orderToCancelId: null,
      couponModalOpen: false,
      couponModelTab: 0
    };
  }

  componentDidMount() {
    this.props.getCompany({
      company_id: this.props.companyId
    },{
      onSuccess: (r) => {
        this.setState({verification_status: r.verification_status});
      }
    });
    this.props.loadWalletInfo({
      company_id: this.props.companyId
    });
    this.props.loadPlansInfo({
      company_id: this.props.companyId
    });
    this.props.loadMovements({
      company_id: this.props.companyId
    });
    this.props.getCoupons({
      company_id: this.props.companyId
    });
    this.props.loadSubscriptionCatalog();
  }

  formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formetedNumber.split(".");
    if (splitArray.length > 1) {
      formetedNumber = splitArray[0];
    }
    return formetedNumber;
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  getUrl = (year, month, type) => {
    return `${SERVER}/api/v2/companies/${this.props.companyId}/subscriptions/?view=statement&month=${month}&year=${year}&action=download_${type}`;
  };

  getStatement = (year, month, type) => {
    Axios({
      url: this.getUrl(year, month, type),
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Edo_Cuenta_".concat(year, "_", month, ".", type === "xls" ? "xlsx" : type));
      // link.setAttribute("download", null);
      document.body.appendChild(link);
      link.click();
    });
  }

  onChange = (e) => {
    // debugger;
    this.setState({[e.target.name]: e.target.value})
  }

  buildDescriptionStatus = (order) => {
    if (order.status === 1) {
      return `Pagar antes de 18:00 del ${moment(order.validity_date).format('DD MMMM YYYY')}`
    } else if (order.status === 2) {
      return `Fecha de recepción de pago ${moment(order.movement_date).format('DD MMMM YYYY')}`
    } else if (order.status === 3 || order.status === 4) {
      return `Fecha de cancelación ${moment(order.movement_date).format('DD MMMM YYYY')}`
    }
    return ''
  }

  getDiscountPercentage = (movements) => {
    
    for(let i = 0; i < movements.length; i++) {
      if (movements[0].discount_percentage) {
        return movements[0].discount_percentage;
      }
    }

    return null;
  }

  buildDiscountDescription = (order) => {
    let discount = this.getDiscountPercentage(order.movements);

    if (discount) {
      return `Descuento ${discount* 100}%`
    }

    return '';
  }

  buildHistoryMovements = () => {
    
    let orders = (this.props.orders || []).map( item => {
      return {
        date: moment(item.created).format('DD MMMM YYYY'),
        title: item.movement_type_description,
        status: `Estatus: ${item.order_status_description}`,
        order:`Pedido #${item.id}`,
        transaction: `${item.movements.length} paquete(s)`,
        qty: '',
        id: item.id,
        order_status: item.status,
        description_date: this.buildDescriptionStatus(item),
        description_discount: this.buildDiscountDescription(item),
        coupon: item.coupon,
        pdf_order: item.pdf_order,
        pdf_invoice:  item.pdf_invoice,
        pdf_receipt: item.pdf_receipt,
        xml_invoice: item.xml_invoice
      };
    })

    return orders;
  }

  getData = () => {
    let bills = [
      {
        month: "Enero",
        bill: () => {this.getStatement(this.state.selectedYear, '01', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '01', 'xls')},
      },
      {
        month: "Febrero",
        bill: () => {this.getStatement(this.state.selectedYear, '02', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '02', 'xls')},
      },
      {
        month: "Marzo",
        bill: () => {this.getStatement(this.state.selectedYear, '03', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '03', 'xls')},
      },
      {
        month: "Abril",
        bill: () => {this.getStatement(this.state.selectedYear, '04', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '04', 'xls')},
      },
      {
        month: "Mayo",
        bill: () => {this.getStatement(this.state.selectedYear, '05', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '05', 'xls')},
      },
      {
        month: "Junio",
        bill: () => {this.getStatement(this.state.selectedYear, '06', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '06', 'xls')},
      },
      {
        month: "Julio",
        bill: () => {this.getStatement(this.state.selectedYear, '07', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '07', 'xls')},
      },
      {
        month: "Agosto",
        bill: () => {this.getStatement(this.state.selectedYear, '08', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '08', 'xls')},
      },
      {
        month: "Septiembre",
        bill: () => {this.getStatement(this.state.selectedYear, '09', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '09', 'xls')},
      },
      {
        month: "Octubre",
        bill: () => {this.getStatement(this.state.selectedYear, '10', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '10', 'xls')},
      },
      {
        month: "Noviembre",
        bill: () => {this.getStatement(this.state.selectedYear, '11', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '11', 'xls')},
      },
      {
        month: "Diciembre",
        bill: () => {this.getStatement(this.state.selectedYear, '12', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '12', 'xls')},
      },
    ];
    // debugger;
    if(this.state.selectedYear === TODAY.getFullYear()){
      bills = bills.slice(0, (TODAY.getMonth() + 1));
    }

    let years = [];
    let year = FIRST_YEAR;
    while(year <= TODAY.getFullYear()){
      years.push(year++);
    }
    
    let result = (this.props.orders || []).filter( item => item.status === 2).length;

    return {
      purchase: {
        // credits: this.formatNumber(this.state.credits),
        credits: (this.props.walletInfo || {balance: '-'}).balance
      },
      account: {
        years,
        selectedYear: this.state.selectedYear,
        onChange: this.onChange,
        bills,
      },
      history: {
        movements: this.formatNumber(this.props.orders.length),
        total: result,
        details: this.buildHistoryMovements(),
      },
    };
  };

  getErrorMessageCompanyVerification = () => {
    let error = '';
    
    switch(this.state.verification_status) {
      case(null):
        error = '<b>Verifica tu información faltante</b>';
        break;
      case(0):
        error = '<b>La verificación de tu empresa esta en estatus no verificada, verifica tu información en el perfil de empresa</b>';
        break;
      case(1):
        error = 'La verificación de tu empresa esta en estatus pendiente';
        break;
      case(2):
        error = 'La verificación de tu empresa fue rechazada, verifica tu información en el perfil de empresa';
        break;
    }

    return error;
  }

  buildCouponVigentes = (coupon) => {
    return(
      <div className="coupon">
        <div>{`Cupón #${coupon.id} por haber recomendado a ${coupon.receiver_user?.username || ''} de la empresa ${coupon.company?.orbi_id || ''}`}</div>
        <div>{`Descuento por ${ coupon.discount * 100 }%`}</div>
        <div>{`Vigente del ${coupon.validity_start ? moment(coupon.validity_start).format('DD/MMMM/YYYY') : '---'} al ${moment(coupon.validity_of_use).format('DD/MMMM/YYYY')}`}</div>
      </div>
    );
  }

  buildCouponDescriptionStatus = (coupon) => {

    let status_id = (coupon.status?.id || 0);
    let status_description = (coupon.status?.description || '');

    if (status_id === 1) {
      return `${status_description} (en espera que el recomendado se registre en orbinetwork dentro de los 60 días posteriores a la emisión de la recomendación)`;
    } else if (status_id === 2) {
      return `${status_description} Descuento por ${ coupon.discount * 100 }%`;
    } else if (status_id === 3) {

      let date_val = (coupon.order_applied && coupon.order_applied.movement_date) ? moment(coupon.order_applied.movement_date).format('DD/MMMM/YYYY') : '';
      let order_id = coupon.order_applied ? coupon.order_applied.id : 0;
      let order_status = coupon.order_applied ? ` ( ${coupon.order_applied?.status_description || ''} )` : '';

      return(
        <>
          {`${status_description} ${coupon.discount_applied * 100}% de descuento en el pedido `} 
          <span
            onClick={() => {
              this.props.history.push(`/company/payments/order/${order_id}`);
            }}
          >{`#${order_id}`}</span>
          {`${order_status} del ${date_val}`}
        </>
      );
    } else {
      return `${status_description}`
    }

  }

  buildCouponInfoInactivo = (coupon) => {
    return(
      <div className="coupon">
        <div>{`Cupón #${coupon.id} por haber recomendado a ${coupon?.email || ''} de la empresa ${coupon.company?.orbi_id || ''}`}</div>
        <div>{this.buildCouponDescriptionStatus(coupon)}</div>
        <div>{`Vigente del ${coupon.created ? moment(coupon.created).format('DD/MMMM/YYYY') : '---'} al ${moment(coupon.validity).format('DD/MMMM/YYYY')}`}</div>
      </div>
    );
  }

  buildCouponInfo = (coupon) => {
    return(
      <div className="coupon">
        <div>{`Cupón #${coupon.id} por haber recomendado a ${coupon?.email || ''} de la empresa ${coupon.company?.orbi_id || ''}`}</div>
        <div>{this.buildCouponDescriptionStatus(coupon)}</div>
        <div>{`Vigente del ${coupon.validity_start ? moment(coupon.validity_start).format('DD/MMMM/YYYY') : coupon.created ? moment(coupon.created).format('DD/MMMM/YYYY') : '---'} al ${moment(coupon.validity_of_use).format('DD/MMMM/YYYY')}`}</div>
      </div>
    );
  }

  buildCouponModal = () => {
    return(
      <CouponModal>
        <div className="title">{'CUPONES'}</div>

        <div className="tabs-container">
          <div 
            className={`tab ${this.state.couponModelTab === 0 ? 'selected' : ''}`}
            onClick={() => {
              this.setState({
                couponModelTab: 0
              })
            }}
          >{'Vigentes'}</div>
          <div 
            className={`tab ${this.state.couponModelTab === 1 ? 'selected' : ''}`}
            onClick={() => {
              this.setState({
                couponModelTab: 1
              })
            }}
          >{'Inactivos'}</div>
          <div 
            className={`tab ${this.state.couponModelTab === 2 ? 'selected' : ''}`}
            onClick={() => {
              this.setState({
                couponModelTab: 2
              })
            }}
          >{'Histórico'}</div>
        </div>

        <div className="coupons-list">
          {
            this.state.couponModelTab === 0 ? this.props.coupons.filter( item => (item?.status?.id || 0) === 2).map( coupon => {
              return this.buildCouponVigentes(coupon);
            }) : ''
          }

          {
            this.state.couponModelTab === 1 ? this.props.coupons.filter( item => (item?.status?.id || 0) === 1).map( coupon => {
              return this.buildCouponInfoInactivo(coupon);
            }) : ''
          }

          {
            this.state.couponModelTab === 2 ? this.props.coupons.filter( item => [3,4,5].includes(item?.status?.id || 0)).sort(function(a,b){
              return new Date(b.modified) - new Date(a.modified);
            }).map( coupon => {
              if (coupon.status.id === 4) {
                return this.buildCouponInfoInactivo(coupon);
              }
              return this.buildCouponInfo(coupon);
            }) : ''
          }
        </div>
      </CouponModal>
    );
  }

  getExpirationDate = () => {

    if (this.props.walletInfo && this.props.walletInfo.expiration_date) {

      let now = moment();
      let expiration_date = moment(this.props.walletInfo.expiration_date);

      if (expiration_date > now) {
        return(
          <span>{`Vigente hasta ${expiration_date.format('DD/MMMM/YYYY')}`}</span>
        );
      } else {
        return <span></span>;
      }
    }

    return <span></span>;

  }

  render() {
    return (
      <Container>
        <MainHeader>
        </MainHeader>

        <MainContainer>
          <div className="left">
            <LeftColumns>
              <div className="panel ">
                <PaymentsPurchase 
                  errorCompanyVerification={this.getErrorMessageCompanyVerification()}
                  data={this.getData().purchase}
                  openBuyOrbibucksAction={() => {
                    this.props.history.push(`/company/payments/buy`)
                  }}
                  coupons={this.props.coupons}
                  openCouponModal={() => this.setState({couponModalOpen: true, couponModelTab: 0})}
                />
              </div>
              <div className="panel">
                <PaymentsAccount data={this.getData().account} />
              </div>
            </LeftColumns>
          </div>
          <div className="right">
            <RightColumns>
              <div className="panel title-panel">
                <div className="info-title">
                  
                  <div>{ this.props.planInfo !== null ? this.props.planInfo.description : ''}</div>
                  {
                    this.getExpirationDate()
                  }
                  <div>Plan Actual</div>
                  {
                    this.props.isCargoOwner ? 
                    <>
                      <Button
                        disabled={this.getErrorMessageCompanyVerification() !== ''}
                        dataTip={this.getErrorMessageCompanyVerification()}
                        dataFor="subscription-tooltip-error"
                        text={ (this.props.planInfo && this.props.planInfo.id === 2) ? "Cambiar subscripción" : "Ver suscripción" }
                        type="primary"
                        onClick={() => {
                          this.props.history.push(`/company/payments/buy`);
                        }}
                      /> 
                      <ReactTooltip
                        id={"subscription-tooltip-error"}
                        place="bottom"
                        type="light"
                        effect="solid"
                        html={true}
                        event="mouseover"
                        eventOff="mouseleave"
                      />
                    </>
                    : ''
                  }
                </div>
              </div>
              <div className="panel">
                <PaymentsHistory 
                  data={this.getData().history}
                  isLoadingCancelling={this.props.isLoadingCancelling}
                  openOrder={(order_id) => {
                    this.props.history.push(`/company/payments/order/${order_id}`);
                  }}
                  cancelOrder={(order) => {
                    let cancelOrderMessage = order.coupon ? '¿Estás seguro que deseas cancelar este pedido? al cancelar se restaura el cupón y lo podrás reutilizar posteriormente' : '¿Estás seguro que deseas cancelar este pedido?'

                    this.setState({
                      cancelOrderMessage,
                      orderToCancelId: order.id
                    });
                  }}
                />
              </div>
            </RightColumns>
          </div>
        </MainContainer>

        <ConfirmDialog
          open={this.state.couponModalOpen}
          title=""
          contentObject={this.buildCouponModal()}
          acceptAction={() => this.setState({couponModalOpen: false})}
          closeAction={() => this.setState({couponModalOpen: false})}
          showCancel={false}
          class='modal-coupons'
          acceptText="Aceptar"
        />

        <GenericDialog
          open={this.state.errorMsg !== ''}
          title="Error!"
          message={this.state.errorMsg}
          acceptAction={() => this.setState({errorMsg: ''})}
          closeAction={() => this.setState({errorMsg: ''})}
        />

        <ConfirmDialog
          closeAction={() => this.setState({cancelOrderMessage: '', orderToCancelId: null})}
          open={this.state.cancelOrderMessage !== ''}
          title={'Cancelar pedido'}
          message={this.state.cancelOrderMessage}
          acceptText={this.props.isLoadingCancelling ? <Loader/> :'Aceptar'}
          acceptAction={() => {

            if (this.props.isLoadingCancelling) {
              return;
            }
            
            this.props.cancelOrder({
              action: 'cancel',
              company_id: this.props.companyId,
              order_id: this.state.orderToCancelId
            }, {
              onSuccess: () => {
                this.setState({orderToCancelId: null, cancelOrderMessage: ''}, () => {
                  this.props.loadMovements({
                    company_id: this.props.companyId
                  });
                });
              },
              onError: (e) => {
                this.setState({
                  orderToCancelId: null, 
                  cancelOrderMessage: '',
                  errorMsg: e.response?.data?.error || "Ocurrió un error, verifica tu información"
                });
              }
            });

          }}
          showCancel={false}
          class='no-bottom-margin'
        />

      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const walletInfo = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.WALLET"); 
  const subscriptionCatalog = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.CATALOGSUBSCRIPTION") || [];
  const orders = getEntityItems(state, "COMPANIES.WALLETMOVEMENTS") || [];
  const coupons = (getEntityItems(state, "COMPANIES.COUPON") || []);//.filter( item => (item?.status?.id || 0) === 2);
  const isLoadingCancelling = state?.api['COMPANIES.WALLETMOVEMENTS.CANCEL']?.status?.isFetching || false;

  let planInfo = subscriptionCatalog.filter( item => {
    return (walletInfo && walletInfo.length > 0 && walletInfo[0].subscription === item.id)
  });


  return {
    companyId,
    walletInfo: walletInfo.length > 0 ? walletInfo[0] : null,
    subscriptionCatalog,
    planInfo: planInfo.length > 0 ? planInfo[0] : null,
    isCargoOwner: `${state.globalCatalog.session.company.type.id}` !== '1',
    orders,
    coupons,
    isLoadingCancelling
  }
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    getCompany: (params, opt) => dispatch(readEntity("COMPANIES.RETRIEVE", params, opt)),
    loadWalletInfo: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.WALLET", params, opt)),
    loadPlansInfo: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.PLANS", params, opt)),
    getCoupons: (params, opt) => dispatch(readEntities("COMPANIES.COUPON", params, opt)),
    loadSubscriptionCatalog: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.CATALOGSUBSCRIPTION", params, opt)),
    loadMovements: (params, opt) => dispatch(readEntities("COMPANIES.WALLETMOVEMENTS", params, opt)),
    cancelOrder: (params, opt) => dispatch(partialEntity("COMPANIES.WALLETMOVEMENTS.CANCEL", params, opt))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsDashboard));

// export default PaymentsDashboard;

const CouponModal = styled.div`

  & .title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  & .tabs-container {
    display: flex;

    & .tab {
      font-size: 15px;
      margin-right: 15px;
      cursor: pointer;

      &.selected {
        border-bottom: 5px solid #2f4ff1;
      }
    }
  }

  & .coupons-list {
    margin-top: 20px;
    height: 300px;
    overflow-y: auto;

    & .coupon {
      font-size: 15px;
      border-bottom: 1px solid black;
      padding-bottom: 15px;
      padding-top: 15px;

      & div {
        line-height: 15px;
      }

      & span {
        color: blue;
        cursor: pointer;
      }
    }
  }
`;