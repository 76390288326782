import * as React from "react";
import { getUrlsEnv } from "../../shared/backend-api";
import SVGIcon, { icons } from "../../shared/svg-icon";

class ProductCard extends React.Component {
  state = {

  }

  static defaultProps = {
    productInfo: {},
    handleChange: () => {}
  }

  handleChange = (e) => {
    if (!RegExp(/^[0-9]*$/).test(e.target.value)) {
      return false;
    }
    this.props.handleChange({target: {
      name: "updateProduct",
      value: {
        id: this.props.productInfo.id, 
        qty: e.target.value, 
        gross_weight: this.props.productInfo.gross_weight || 0,
        gross_weight_description: (this.props.productInfo?.data?.sat_gross_weight_unit?.clave || '').toLowerCase(),
        net_weight: this.props.productInfo.net_weight || 0, 
        net_weight_description: (this.props.productInfo?.data?.sat_net_weight_unit?.clave || '').toLowerCase(),
        volume: this.props.productInfo.volume || 0,
        volume_description: (this.props.productInfo?.data?.sat_volume_unit?.clave || '').toLowerCase(),
        qty_per_sku: e.target.value,
        product_unit_description: (this.props.productInfo?.data?.sat_product_unit?.clave || '').toLowerCase(),
        data: {
          tariff_fraction: this.props.productInfo?.tariff_fraction,
          amount: this.props.productInfo?.commodity_value,
          currency_key: this.props.productInfo?.data?.sat_currency?.clave || 'MXN'
        },
        product: this.props.productInfo
      }
    }})
  }

  getGrossWeight = () => {
    if ((this.props.productInfo?.data?.sat_gross_weight_unit?.clave || '').toLowerCase() === 'tne') {
      return `${(parseFloat(this.props.productInfo.gross_weight) * 1000).toFixed(2)} kg`;
    }
    
    return `${this.props.productInfo.gross_weight} kg`;
  }

  getNetWeight = () => {
    if ((this.props.productInfo?.data?.sat_net_weight_unit?.clave || '').toLowerCase() === 'tne') {
      return `${(parseFloat(this.props.productInfo.net_weight) * 1000).toFixed(2)} kg`;
    }
    
    return `${this.props.productInfo.net_weight} kg`;
  }

  render() {
    return (
      <div className="product-card">
        <div className="product-image">
          <img 
            alt="" 
            src={this.props.productInfo.url ? getUrlsEnv().files.concat(this.props.productInfo.url) : "/images/interrogacion.svg"}
            className={this.props.productInfo.url ? "" : "default"}
          />
          <div>
            sku: {this.props.productInfo.sku}
          </div>
        </div>
        <div className="top-row">
          <div className="added" style={{opacity: this.props.qty ? "1" : "0"}}>
            <SVGIcon name={icons.checkMark} fill="#40D099" /><br/>
            Agregado
          </div>
          <div className="product-weigth">
            {/* Unidades: <br/>
            {"1 "} {(this.props.unitCatalog.find(u => u.id === this.props.productInfo.product_unit) || {code: ""}).code} */}
          </div>
        </div>
        <div className="product-name">
          {this.props.productInfo.description}
        </div>
        <div className="product-weigth">
          Peso Neto: {this.getGrossWeight()}
        </div>
        <div className="product-weigth">
          Peso Total: {this.getNetWeight()}
        </div>
        <div className="product-weigth">
          Cantidad: <input type="text" value={this.props.qty} onChange={this.handleChange} 
            onBlur={(e) => { 
              if (parseInt(e.target.value) < 1) {
                this.props.handleChange({target: {
                  name: "updateProduct",
                  value: {
                    id: this.props.productInfo.id, 
                    qty: '',
                  }
                }})
              }
            }}
          />
        </div>
      </div>
    )
  }
}

export default ProductCard;