import * as React from "react";
import Modal from "react-modal";
import Button from "../../shared/controls/button";
import TextInput from "../../shared/controls/text-input";
import { calculateGrossWeightProducts, calculateNetWeightProducts, calculateVolumeProducts, normalizeStr, US_HOURLY_FORMAT } from "../../shared/utils";
import ModalSelectInput from "../shipment/modal-select-input";
import ProductCard from "./product-card";
import ProductRow from "./product-row";
import Loader from "../../shared/icons/tat/loader";
import styled from 'styled-components';
import { cloneDeep } from "lodash";
import SVGIcon, { icons } from "../../shared/svg-icon";
import moment from 'moment';

class CreateOrderModal extends React.Component {
  state = {
    stage: 1,
    orderModalSearchStr: "",
    offsetProducts: 1,
    codeDuplicated: false
    // products: []
  };

  static defaultProps = {
    onClose: () => {},
    selectedProducts: [],
    show: false
  }

  clearSearch = () => {
    this.state.offsetProducts = 1;

    this.props.handleChange({
      target: { name: "searchProduct", value: { search_value: '', offset: this.state.offsetProducts } },
    });
    this.setState({
      orderModalSearchStr: ""
    });
  }

  nextStage = () => {
    this.setState({
      stage: this.state.stage + 1,
    })
  }

  prevStage = () => {
    this.setState({
      stage: this.state.stage - 1
    })
  }

  onClose = () => {
    this.setState({
      stage: 1
    })
    this.props.onClose();
  }

  finalizeOrder = () => {
    if(this.state.stage === 3 && !this.props.selectedProducts.length){
      this.nextStage();
    } else {

      this.props.handleChange({
        target: { 
          name: "validateCode", 
          value: { 
            found_callback: () => { this.setState({codeDuplicated: true}) },
            no_found_callback: () => { 
              this.props.handleChange({
                target: {
                  name: "createOrder",
                  value: this.onClose
                }
              })
            }
          }
        }
      });
    }
  }

  getTitle = () => {
    switch(this.state.stage){
      case 1:
        return this.props.currentFacilityName === undefined ? "Pedido de Compra" : "Pedido de Compra para ".concat(this.props.currentFacilityName);
      case 2:
        return "Agrega Productos";
      case 3: 
        return "Confirmar Pedido de Compra N°".concat(this.props.selectedCode);
      case 4:
        return "Crear Pedido";
      case 5:
        return "Confirmación de Pedidos";
      default: 
        return ""
    }
    // return "Orden de compra para"
  }

  handleChange = (e) => {
    // debugger;
    this.setState({[e.target.name]: e.target.value})
  }

  searchValue = (items, value) => {
    for (let i = 0; i < items.length; i++) {

      if ('options' in items[i]) {
        let options = items[i].options || [];

        for (let j = 0; j < options.length; j++) {
          if (`${options[j].value}` === `${value}`) {
            return options[j];
          }
        }

      } else {
        
        if (`${items[i].value}` === `${value}`) {
          return items[i];
        }

      }

    }

    return null;
  }

  handleScroll = (e) => { 
    this.scrollTop = e.target.scrollTop;
    const bottom = (e.target.scrollHeight - e.target.scrollTop) < (e.target.clientHeight + 10);
    if (bottom && !this.props.isLoadingProducts && this.props.moreProducts) {
      this.props.handleChange({
        target: { name: "searchProduct", value: { search_value: this.state.orderModalSearchStr, offset: this.state.offsetProducts + 1 } },
      });
      this.setState({offsetProducts: this.state.offsetProducts + 1});
    }
  }

  getFacilitiesDestiny = () => {

    let facilities_destiny = cloneDeep(this.props.facilitiesDestiny || []);
    let facility_selected = `${this.props.selectedFacility}`;
    let result = [];

    for (let i = 0; i < facilities_destiny.length; i++) {
      let facility = facilities_destiny[i];
      if ('options' in facility) {

        let options = facility.options || [];
        let new_options = [];

        for (let j = 0; j < options.length; j++) {
          if (`${options[j].value}` !== facility_selected) {
            new_options.push(options[j]);
          }
        }

        facility.options = new_options;
        result.push(facility);

      } else {
        if (`${facility.value}` !== facility_selected) {
          result.push(facility);
        }
      }

    }

    return result;
  }

  getContent = () => {
    switch(this.state.stage){
      case 5:
        return(
          <div style={{fontSize: "23px", margin: "25px 0"}}>
            ¿Estás seguro de crear el pedido?
          </div>
        );
      case 1:
        return (
          <div className="first-step-options">
            <ModalSelectInput
              isSearchable={false}
              isDisabled={false}
              // defaultValue={this.props.providerCatalog[0]}
              onChange={(a) =>
                this.props.handleChange({
                  target: { name: "selectedProvider", value: a.value },
                })
              }
              name="selectedProvider"
              placeholder="Proveedor"
              value={this.props.providerCatalog.find(fC => fC.value === this.props.selectedProvider) || null}
              options={this.props.providerCatalog}
            />
            <div style={{width: 8}}/>
            <ModalSelectInput
              isSearchable={false}
              isDisabled={!this.props.selectedProvider}
              // defaultValue={this.props.facilitiesCatalog[0]}
              onChange={(a) =>
                this.props.handleChange({
                  target: { name: "selectedFacility", value: a.value },
                })
              }
              name="selectedFacility"
              placeholder="Sucursal Origen"
              value={this.searchValue(this.props.facilitiesCatalog, this.props.selectedFacility)}
              options={this.props.facilitiesCatalog}
            />
            <div style={{width: 8}}/>
            {
              this.props.currentFacilityName === undefined &&
              <>
                <ModalSelectInput
                  isSearchable={false}
                  isDisabled={!this.props.selectedFacility}
                  onChange={(a) =>
                    this.props.handleChange({
                      target: { name: "selectedDestiny", value: a.value },
                    })
                  }
                  name="selectedDestiny"
                  placeholder="Sucursal Destino"
                  value={this.searchValue(this.props.facilitiesDestiny, this.props.selectedDestiny)}
                  options={this.getFacilitiesDestiny()}
                />
                <div style={{width: 8}}/>
              </>
            }
            <div>
              <TextInput
                label="Número de Pedido"
                name="selectedCode"
                value={this.props.selectedCode}
                className="item"
                onChange={this.props.handleChange}
                isUserTyping={() => {
                  if (this.state.codeDuplicated) {
                    this.setState({codeDuplicated: false});
                  }
                }}
                // validate={[validators.required, "maxLength"]}
                // validationParam={4}
                // validate={[validators.required]}
                //   onFocused={onFocused}
                // disabled={true}
              />
              {
                this.state.codeDuplicated &&
                <RecommendationError>
                  {'El código ya está en uso'}
                </RecommendationError>
              }
            </div>
            <div style={{width: 8}}/>
            <div>
              <TextInput
                type="date"
                format={"dd/MMM/yyyy"}
                minDate={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 1
                  )
                }
                value={
                  this.props.selectedDate
                }
                label="Fecha Deseada de Entrega"
                name="selectedDate"
                onChange={this.props.handleChange}
                showTimeSelect={false}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
            </div>
          </div>
        )
      case 2: 
        return (
          <div className="second-step">
            <div className="headers">
              
              <TextInput
                label="Buscar Producto"
                name="orderModalSearchStr"
                value={this.state.orderModalSearchStr}
                className="search"
                onChange={(e) => {
                  this.state.offsetProducts = 1;
                  this.handleChange(e);

                  let search_value = e.target.value || '';
                  if (search_value.length > 2) {
                    this.props.handleChange({
                      target: { name: "searchProduct", value: { search_value: e.target.value, offset: this.state.offsetProducts } },
                    });
                  } else if (search_value.length === 0) {
                    this.props.handleChange({
                      target: { name: "searchProduct", value: { search_value: '', offset: this.state.offsetProducts } },
                    });
                  }
                }}
                // validate={[validators.required, "maxLength"]}
                // validationParam={4}
                // validate={[validators.required]}
                //   onFocused={onFocused}
                // disabled={true}
              />

              <div className="counters">
                {
                  // this.props.selectedProducts.length 
                  `${this.props.selectedProducts.length} Producto(s) Agregado(s)`
                  // : "Carrito vacio"
                }
              </div>
            </div>
            <div 
              className="cards-container"
              onScroll={this.handleScroll} 
            >
              {
                this.props.productCatalog
                  // .filter(p => 
                  //   normalizeStr(p.sku || "").includes(normalizeStr(this.state.orderModalSearchStr)) 
                  //   || normalizeStr(p.description || "").includes(normalizeStr(this.state.orderModalSearchStr))
                  // )
                  .map(p => {
                  return <ProductCard 
                    key={p.id}
                    productInfo={p} 
                    unitCatalog={this.props.unitsCatalog}
                    qty={(this.props.selectedProducts.find(sP => sP.id === p.id) || {qty: ''}).qty}
                    handleChange={this.props.handleChange}
                  />
                })
              }
            </div>
            {
              this.props.isLoadingProducts &&
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Loader circleColor={'black'} width="40px" height="40px"/>
              </div>
            }
          </div>
        )
      case 3:
        return (
          <div className="third-step">
            <div className="headers">
              <div className="avatar">
                <div className="picture"></div>
                <div className="data">
                  <div><b>Proveedor:</b> {(this.props.providerCatalog.find(p => p.value === this.props.selectedProvider) || {label: "-"}).label}</div>
                  <div><b>Origen:</b> {(this.searchValue(this.props.facilitiesCatalog, this.props.selectedFacility) || {label: "-"}).label}</div> 
                </div>
              </div>
              <div className="avatar">
                <div className="picture"></div>
                <div className="data">
                  <div>
                    <b>Fecha Deseada de Entrega: </b> 
                    { this.props.selectedDate ? moment(this.props.selectedDate, "MM/DD/YYYY").format("DD/MMM/YYYY") : '-' }
                  </div>
                  <div>
                    <b>Destino: </b> 
                    { this.props.currentFacilityName === undefined ? (this.searchValue(this.props.facilitiesDestiny, this.props.selectedDestiny) || {label: "-"}).label : this.props.currentFacilityName }
                  </div>
                </div>
              </div>
            </div>
            {
              this.props.selectedProducts.length ?
              <>
                <div className="table-title">
                  Detalles del Pedido:
                </div>
                <div className="table-header">
                  <div style={{width: "30%"}}>Producto</div>
                  <div style={{width: "19%"}}>SKU</div>
                  <div style={{width: "10%"}}>Cantidad</div>
                  <div style={{width: "9%"}}>Unidades</div>
                  <div style={{width: "10%"}}>Peso Total</div>
                  <div style={{width: "10%"}}>Peso Neto</div>
                  <div style={{width: "10%"}}>Volumen</div>
                  <div style={{width: "2%"}}></div>
                </div>
                <div className="table-body">
                  {
                    this.props.selectedProducts
                    .map(sP => {
                      let productInfo = sP.product;
                      return <ProductRow 
                        key={productInfo.id}
                        productInfo={{...productInfo, product: productInfo}} 
                        unitCatalog={this.props.unitsCatalog}
                        qty={sP.qty}
                        removeProduct={(id) => {
                          this.props.handleChange({target: {name:'removeProduct', value: id}});
                          if (this.props.selectedProducts.length < 2) {
                            this.setState({stage: 2})
                          }
                        }}
                      />
                    })
                  }
                </div>
                <div className="resume">
                  <div style={{width: "30%"}}>Total</div>
                  <div style={{width: "19%"}}>{this.props.selectedProducts.length}</div>
                  <div style={{width: "10%"}}>{this.props.selectedProducts.reduce((acum, value) => {return (acum + Number(value.qty))}, 0)}</div>
                  <div style={{width: "9%"}}></div>
                  <div style={{width: "10%"}}>{calculateGrossWeightProducts(this.props.selectedProducts)}</div>
                  <div style={{width: "10%"}}>{calculateNetWeightProducts(this.props.selectedProducts)}</div>
                  <div style={{width: "10%"}}>{calculateVolumeProducts(this.props.selectedProducts)}</div>
                  <div style={{width: "2%"}}></div>
                </div>
                {
                  this.state.codeDuplicated &&
                  <RecommendationError>
                    {'El código ya está en uso'}
                  </RecommendationError>
                }
              </>
              : ''
            }
            {/* <div className="table-title">
              Detalles del envío:
            </div>
            <div>

            </div> */}
          </div>
        )
      case 4:
        return (
          <div style={{fontSize: "23px", margin: "25px 0"}}>
            ¿Estás seguro de crear orden sin producto?
          </div>
        )
    }
    return <div></div>
  }

  validateFacilityDestiny = () => {
    return this.props.currentFacilityName === undefined && this.props.selectedDestiny === null
  }

  render() {
    return(
      <Modal
        isOpen={this.props.show}
        portalClassName="dialog emails-modal invite-users-modal create-orders-modal"
        overlayClassName="overlay"
        className="content"
      >
        <div className="title">
          {this.getTitle()}
        </div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">{this.getContent()}</div>
        <div style={{height: 30}}/>
        <div className="actions">
          {/* {
            this.state.stage === 1 &&
            <Button
              text={this.props.isValidationLoading ? <Loader/> : "Continuar sin producto"}
              type="secondary"
              onClick={() => {
                this.props.handleChange({
                  target: { 
                    name: "validateCode", 
                    value: { 
                      found_callback: () => { this.setState({codeDuplicated: true}) },
                      no_found_callback: () => { this.setState({stage: 3}) }
                    }
                  }
                });
              }}
              disabled={!this.props.selectedProvider || !this.props.selectedFacility || !this.props.selectedCode || this.validateFacilityDestiny() || this.props.isValidationLoading}
            />
          } */}
          {
            (this.state.stage === 2 || this.state.stage === 3) &&
            <Button
              text={"Regresar"}
              type="primary outline"
              onClick={() => {this.prevStage(); this.clearSearch()}}
              disabled={this.props.isValidationLoading || this.props.isCreationLoading}
            />
          }
          {
            (this.state.stage === 5) &&
            <Button
              text={"Regresar"}
              type="primary outline"
              onClick={() => {this.setState({stage: 3})}}
              disabled={this.props.isCreationLoading}
            />
          }
          {
            this.state.stage === 1 &&
            <Button
              text={this.props.isValidationLoading ? <Loader/> : "Continuar"}
              type="primary"
              onClick={() => {
                this.props.handleChange({
                  target: { 
                    name: "validateCode", 
                    value: { 
                      found_callback: () => { this.setState({codeDuplicated: true}) },
                      no_found_callback: () => { this.nextStage() }
                    }
                  }
                });
              }}
              disabled={!this.props.selectedProvider || !this.props.selectedFacility || !this.props.selectedCode || this.validateFacilityDestiny() || this.props.isValidationLoading}
            />
          }
          {
            this.state.stage === 2 &&
            <Button
              text={"Ir al Carrito"}
              type="primary"
              onClick={() => {this.nextStage(); this.clearSearch()}}
              disabled={this.props.selectedProducts.length < 1}
            />
          }
          {
            this.state.stage === 4 &&
            <Button
              text={"Agregar Producto"}
              type="primary outline"
              onClick={() => {this.setState({stage: 2})}}
              disabled={false}
            />
          }
          {
            (this.state.stage === 4 || this.state.stage === 3) &&
            <Button
              text={(this.props.isCreationLoading || this.props.isValidationLoading) ? <Loader circleColor={'black'}/> : "Crear Pedido"}
              type="primary outline"
              onClick={this.finalizeOrder}
              disabled={this.props.isCreationLoading || this.state.codeDuplicated || this.props.isValidationLoading}
            />
          }
        </div>
        <div style={{height: 20}}/>
      </Modal>
    )
  }
}

export default CreateOrderModal;

export const RecommendationError = styled.div`
  color: red;
  font-size: 13px;
  display: flex;
  justify-content: center;
`