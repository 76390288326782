import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HelperFilesModal from "../../shared/component/helper_files_modal";
import styled from "styled-components";
import { TabContainer } from "./style";
import { Flex } from "../../shared/styled/flex";
import OptionSelectorSwitch from "../../shared/controls/option-selector-switch";
import OrdersSummaryList from "../../control/orders/orders-summary-list";
import { cancelRequest, createEntity, getEntityItems, readEntities, readEntity } from "../../redux/api";
import { View } from "../../shared/styled/view";
import Loader from '../../shared/icons/tat/loader';
import Button from "../../shared/controls/button";
import CreateOrderModal from "../../control/orders/create-order-modal";
import { downloadPermissionFile, loadSession } from "../../redux/reducers/global-catalog.reducer";
import { cancelMultipleRequests } from "../../redux/api/actions";
import { sendMessageToMultipleUsersInSocket, showSimpleFlashNotification } from "../../shared/utils";
import GenericDialog from "../../shared/dialog/generic-modal";
import moment from 'moment';

const ITEMS = [
  { description: "FTL", value: 1 },
  { description: "LTL", value: 2 },
]

const TRANSACTIONS = [
  { description: "Compra", value: 1 },
  { description: "Venta", value: 2 },
  { description: "Todo", value: 4 },
]

class HomeCoView extends React.Component {

  state = {
    tab_selected: 1,
    second_tab_selected: 1,
    third_tab_selected: 1,
    showCreateOrderModal: false,

    productsToAdd: [],
    selectedProvider: 0,
    selectedFacility: 0,
    selectedTags: [],
    selectedCode: "",

    facilityCatalog: [],
    selectedDestiny: null,
    providerProducts: [],
    moreProducts: true,
    selectedDate: null,
    error_message: null,
    tabSelectedByMenu : ''
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.cancelUpdate, true);
  }

  componentDidMount() {

    if (this.props.isCO) {
      this.initData();
    } else {
      this.setState({
        tab_selected: 5
      }, () => {
        this.initData();
      });
    }

  }

  componentDidUpdate(prepProps) {
    
    if (this.props.location.search.includes('tab')) {
      const query = new URLSearchParams(this.props.location.search);
      const current_tab = query.get('tab');

      if (current_tab !== this.state.tabSelectedByMenu) {
        this.setState({
          tabSelectedByMenu: query.get('tab'),
          tab_selected: current_tab === 'ordenes' ? 3 : 1
        }, () => {
          this.loadItems();
        });
      }
    } else {
      if (this.state.tabSelectedByMenu !== '') {
        this.setState({
          tabSelectedByMenu: '',
          tab_selected: 1
        }, () => {
          this.loadItems();
        });
      }
    }

  }

  initData = () => {
    document.addEventListener("keydown", this.cancelUpdate);
    this.loadItems();

    this.props.loadCompanyUnits({
      company_id: this.props.companyId,
    });
    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
    });
  }

  loadProviderFacilities = () => {
    if (this.state.selectedProvider === '-1') {
      return
    }

    this.props.loadProviderFacilities({
      company_id: this.props.companyId,
      network_id: this.state.selectedProvider
    });
  }

  loadProviderProducts = (search_value, offset) => {
    if (this.state.selectedProvider === '-1') {
      this.props.loadProducts({
        company_id: this.props.companyId,
        view: 'short_detail',
        tags: '',
        skus: '',
        products: '',
        search: search_value || '',
        limit: '',
        offset: offset || '',
        isMultipleCancels: true
      }, {
        onSuccess: (response) => {
          this.setState({
            moreProducts: `${offset}` === '1' ? true : response.length > 0,
            providerProducts: `${offset}` === '1' ? response : this.state.providerProducts.concat(response)
          })
        }
      });
      return
    }

    this.props.loadProviderProducts({
      company_id: this.props.companyId,
      network_id: this.state.selectedProvider,
      search: search_value || '',
      offset: offset || '',
      isMultipleCancels: true
    }, {
      onSuccess: (response) => {
        this.setState({
          moreProducts: `${offset}` === '1' ? true : response.length > 0,
          providerProducts: `${offset}` === '1' ? response : this.state.providerProducts.concat(response)
        })
      }
    });
  }

  cancelUpdate = (e) => {
    if(e.key === "Escape" && this.props.isLoading){
      this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.ORDERS.RESUME");
      this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUME");
      this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUMETR");
      this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.RESUME");
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  loadItems = () => {
    this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.ORDERS.RESUME");
    this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUME");
    this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.RESUME");
    this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUMETR");

    switch(this.state.tab_selected){
      case 1:
        this.props.loadCompanyRequisitionsResume({
          company_id: this.props.companyId
        }, {
          onSuccess: (response) => {

            let tags = Object.entries(response['data'] || {});
            let all_facilities_group = [];

            tags.map( item => {

              if (item.length > 1) {

                let item_to_add = {
                  label: item[0]
                }
                let facilities = [];

                (item[1] || []).map( item_2 => {
                  facilities.push({
                    value: `${item_2.id}`,
                    id: item_2.id,
                    label: `${item_2.name} - ${item_2.alias} - ${item_2.code}`
                  });

                });

                item_to_add.options = facilities;
                all_facilities_group.push(item_to_add);
              }

            });

            this.setState({
              facilityCatalog: all_facilities_group
            });
          }

        });
      break;
      case 2:
        this.props.loadCompanyOrdersResume({
          company_id: this.props.companyId
        })
      break;
      case 3:
        this.props.loadCompanyOrdersResume({
          company_id: this.props.companyId
        })
      break;
      case 4:
        this.props.loadCompanyTripsResume({
          company_id: this.props.companyId
        });
      break;
      case 5:
        this.props.loadCompanyTrTripsResume({
          company_id: this.props.companyId
        });
      break;
      default:
    }
  }

  buildEmptySpace = () => {

    if (this.props.isLoading) {
      return '';
    }

    if (
      (this.state.tab_selected === 1 && this.props.requisitionsResume.length === 0) ||
      (this.state.tab_selected === 3 && this.props.ordersResume.length === 0) ||
      (this.state.tab_selected === 4 && this.props.tripsResume.length === 0) ||
      (this.state.tab_selected === 5 && this.props.trTripsResume.length === 0)
    ) {
      return(
        <NoResultsStyled>
          No se encontró ningún dato
          <LoadContentStyled>
            <Button
              settings={{
                type: "btn primary small",
                text: "Cargar",
                onClick: () => this.loadItems(),
              }}
            />
          </LoadContentStyled>
        </NoResultsStyled>
      );
    }

    return '';

  }

  getResume = () => {
    if (this.state.tab_selected === 1) {
      return this.props.requisitionsResume;
    }

    if (this.state.tab_selected === 3) {
      return this.props.ordersResume;
    }

    if (this.state.tab_selected === 4) {
      return this.props.tripsResume;
    }

    if (this.state.tab_selected === 5) {
      return this.props.trTripsResume;
    }

    return [];
  }

  getResumeCounter = () => {
    if (this.state.tab_selected === 1) {
      return this.props.requisitionsResumeCounter;
    }

    if (this.state.tab_selected === 3) {
      return this.props.ordersResumeCounter;
    }

    if (this.state.tab_selected === 4) {
      return this.props.tripsResumeCounter;
    }

    if (this.state.tab_selected === 5) {
      return this.props.trTripsResumeCounter;
    }

    return {};
  }

  resetModal = () => {
    this.setState({
      productsToAdd: [],
      selectedProvider: 0,
      selectedFacility: 0,
      selectedTags: [],
      selectedOrdersId: [],
      selectedCode: "",
      selectedDate: null,
      selectedLevel: 1,
      selectedClass: 0,
      selectedDestiny: null,
      providerProducts: [],
      providerFacilities: []
    })
  }

  handleModalChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch(name){
      case 'selectedFacility':
        this.state.selectedDestiny = null;
        this.setState({[name]: value});
      break;
      case "validateCode":
        let found_callback = value.found_callback || null;
        let no_found_callback = value.no_found_callback || null;

        this.props.validateCode({
          company_id: this.props.companyId,
          view: 'validate',
          code: this.state.selectedCode
        }, {
          onSuccess: (response) => {

            if ('is_found' in response && response.is_found && found_callback) {
              found_callback()
            } else {
              if (no_found_callback) no_found_callback();
            }

          }
        });
      break;
      case "searchProduct":
        let search_value = value.search_value || '';
        let offset = value.offset || '';

        this.props.cancelMultipleRequests();
        this.loadProviderProducts(search_value, offset);
        break;
      case "selectedDestiny":
        this.setState({
          selectedDestiny: value
        });
        break;
      case "selectedProvider": 
        this.setState({
          selectedProvider: value, 
          selectedFacility: 0,
          selectedDestiny: null,
          providerProducts: [],
          productsToAdd: []
        }, () => {
          this.loadProviderFacilities();
          this.loadProviderProducts('','');
        });
        break;
      // case "addProduct":
      //   this.setState({productsToAdd: this.state.productsToAdd.concat([{id: value.id, qty: value.qty}])})
      //   break;
      case "updateProduct":
        let products = this.state.productsToAdd.filter(p => p.id !== value.id);
        // let index = this.state.productsToAdd.map(p => p.id).indexOf(value.id);
        // let selectedProduct = products.splice(index,1)[0];
        if(value.qty){
          products.push({
            id: value.id,
            qty: value.qty,
            gross_weight: value.gross_weight || 0,
            gross_weight_description: value.gross_weight_description || '',
            net_weight: value.net_weight || 0, 
            net_weight_description: value.net_weight_description || '',
            volume: value.volume || 0,
            volume_description: value.volume_description || '',
            qty_per_sku: value.qty,
            product_unit_description: value.product_unit_description || '',
            data: value.data || {},
            product: value.product
          })
        }
        this.setState({productsToAdd: products});
        break;
      case "removeProduct":
        let products1 = this.state.productsToAdd.filter(p => p.id !== value);
        // let index = this.state.productsToAdd.map(p => p.id).indexOf(value.id);
        // let selectedProduct = products.splice(index,1)[0];
        this.setState({productsToAdd: products1});
        break;
      
      case "createOrder":
        let reqProducts = [];
        this.state.productsToAdd.forEach(p => {
          let qty = Number(p.qty);
          reqProducts.push({id: p.id, qty})
        });

        let delivery_date = null;
        
        if (this.state.selectedDate !== null) {
          delivery_date = moment(this.state.selectedDate, "MM/DD/YYYY").format('YYYY-MM-DD');
        }

        this.props.createRequisitionV2({
          company_id: this.props.companyId,
          products: reqProducts,
          from_facility: Number(this.state.selectedFacility),
          to_facility: Number(this.state.selectedDestiny),
          code: this.state.selectedCode,
          network_id: this.state.selectedProvider !== '-1' ? Number(this.state.selectedProvider) : null,
          delivery_date
        }, {
          onSuccess: (result) => {
            value();
            this.loadItems();

            showSimpleFlashNotification('Se creó con éxito el pedido', null);

            try {
              sendMessageToMultipleUsersInSocket(
                this.props.userId,
                result['users'],
                result['data']
              );
            } catch(e) {
              console.log('requisition creation error:', e)
            }

          },
          onError: (e) => {
            value();
            this.setState({
              error_message: e.response?.data?.error || 'Ocurrío un error, intente de nuevo'
            })
          }
        });
        break;
      default:
        this.setState({[name]: value});
    }
  }

  getTabs = () => {
    if (!this.props.isCO) {
      return(
        <div 
          className={`tab ${this.state.tab_selected === 5 ? 'selected' : ''}`}
          onClick={() => {
            if (this.state.tab_selected !== 5) {
              this.setState({tab_selected: 5}, () => this.loadItems())
            }
          }}
        >Embarques</div>
      );
    }

    if (this.state.tabSelectedByMenu === 'pedidos') {
      return(
        <div 
          className={`tab ${this.state.tab_selected === 1 ? 'selected' : ''}`}
          onClick={() => {
            if (this.state.tab_selected !== 1) {
              this.setState({tab_selected: 1}, () => this.loadItems())
            }
          }}
        >Pedidos</div>
      );
    } else if (this.state.tabSelectedByMenu === 'ordenes') {
      return(
        <div 
          className={`tab ${this.state.tab_selected === 3 ? 'selected' : ''}`}
          onClick={() => {
            if (this.state.tab_selected !== 3) {
              this.setState({tab_selected: 3}, () => this.loadItems())
            }
          }}
        >Órdenes</div>
      );
    } else {
      return(
        <>
          <div 
            className={`tab ${this.state.tab_selected === 1 ? 'selected' : ''}`}
            onClick={() => {
              if (this.state.tab_selected !== 1) {
                this.setState({tab_selected: 1}, () => this.loadItems())
              }
            }}
          >Pedidos</div>

          <div 
            className={`tab ${this.state.tab_selected === 3 ? 'selected' : ''}`}
            onClick={() => {
              if (this.state.tab_selected !== 3) {
                this.setState({tab_selected: 3}, () => this.loadItems())
              }
            }}
          >Órdenes</div>

          <div 
            className={`tab ${this.state.tab_selected === 4 ? 'selected' : ''}`}
            onClick={() => {
              if (this.state.tab_selected !== 4) {
                this.setState({tab_selected: 4}, () => this.loadItems())
              }
            }}
          >Embarques</div>
        </>
      );
    }


  }

  render() {

    return(
      <View className="orders-control-view">
        <div
          style={{
            display: 'flex',
            marginRight: 60,
            flexDirection: 'row-reverse',
            marginBottom: 15
          }}
        >
          <HelperFilesModal
            files={[]}
          />
        </div>

        {
          (this.props.isLoading) && 
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000080",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1001,
              backdropFilter: "blur(3px)"
            }}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Loader width="100px" height="100px"/>
              <div style={{color: "#fff", fontSize: "36px"}}>
                Cargando
              </div>
            </div>
            <div style={{color: "#fff", fontSize: "30px"}}>
              Si desea cancelar la consulta, presione ESC
            </div>
          </div>
        }
        
        <TabContainer>

          { this.getTabs() }

        </TabContainer>

        <Flex>
          <div style={{ width: "150px", marginRight: "auto", marginLeft: "0" }}>
            {
              (this.state.tab_selected === 4 || this.state.tab_selected === 5) &&
              <OptionSelectorSwitch
                value={this.state.third_tab_selected}
                name="third_tab_selected"
                onChange={this.handleChange}
                items={ITEMS}
              />
            }
          </div>

          <div style={{width: "30%"}}>
            {
              this.state.tab_selected !== 5 &&
              <OptionSelectorSwitch
                value={this.state.second_tab_selected}
                name="second_tab_selected"
                onChange={this.handleChange}
                items={TRANSACTIONS}
              />
            }
          </div>

          <div style={{ width: (this.state.tab_selected === 1 && [1,4].includes(this.state.second_tab_selected)) ? "400px" : "200px", marginLeft: "auto", marginRight: "0", display: 'flex' }}>
            <Button 
              key={'btn-to-invite-companies'} 
              settings={{
                type: 'btn secondary',
                text: 
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: 10,
                      paddingRight: 10
                    }}
                  > 
                    <img
                      alt=""
                      src="/images/invite_company.png"
                      style={{width: 20, height: 20, marginRight: 10, filter: 'invert(1)'}}
                    />
                    {"Invitar Empresas"}
                  </div>,
                onClick: () => this.props.history.push('/company/network?inviteModal=true')
              }}
            />
            {
              (this.state.tab_selected === 1 && [1,4].includes(this.state.second_tab_selected)) && 
              <>
                <div style={{width: 10}}/>
                <Button
                  settings={{
                    disabled: ![1,4].includes(this.state.second_tab_selected),
                    type: "primary",
                    text: 'Crear Pedido de Compra',
                    onClick: () => this.setState({showCreateOrderModal: true})
                  }}
                />
              </>
            }
          </div>
        </Flex>
        
        <div className="content">
          <OrdersSummaryList
            simpleRow={this.state.tab_selected === 5}
            disabled={!this.props.isCO}
            history={this.props.history}
            ordersResume={this.getResume()}
            itemType={this.state.tab_selected}
            transactionType={this.state.tab_selected === 5 ? 4 :this.state.second_tab_selected}
            transportType={this.state.third_tab_selected}
            columnCounter={this.getResumeCounter()}
          />
        </div>

        { this.buildEmptySpace() }

        {
          this.state.showCreateOrderModal &&
          <CreateOrderModal
            show={this.state.showCreateOrderModal}
            onClose={() => {this.resetModal(); this.setState({showCreateOrderModal: false})}}
            handleChange={this.handleModalChange}

            selectedProducts={this.state.productsToAdd}
            selectedProvider={this.state.selectedProvider}
            selectedFacility={this.state.selectedFacility}
            selectedTags={this.state.selectedTags}
            selectedCode={this.state.selectedCode}
            selectedDestiny={this.state.selectedDestiny}

            productCatalog={this.state.providerProducts}
            providerCatalog={this.props.companyProviders}
            facilitiesCatalog={
              this.state.selectedProvider === '-1' ? this.state.facilityCatalog : this.props.providerFacilities
            }
            tagsCatalog={[]}
            unitsCatalog={this.props.companyUnits}

            facilitiesDestiny={this.state.facilityCatalog}
            isLoadingProducts={this.props.isLoadingProducts}
            moreProducts={this.state.moreProducts}
            isValidationLoading={this.props.isValidationLoading}
            isCreationLoading={this.props.isRequisitionCreationLoading}
            selectedDate={this.state.selectedDate}
          />
        }

        {
          this.state.error_message &&
          <GenericDialog
            open={this.state.error_message !== null}
            title={'Error'}
            message={this.state.error_message}
            acceptAction={() => this.setState({error_message: null})}
            closeAction={() => this.setState({error_message: null})}
          />
        }
        
      </View>
    );

  }
}

const mapStateToProps = (state) => {
  const companyId = state?.globalCatalog?.session?.company?.id || -1; 
  const ordersResume = Object.entries(getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.ORDERS.RESUME")['data'] || {});
  const tripsResume = Object.entries(getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUME")['data'] || {});
  const requisitionsResume = Object.entries(getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.RESUME")['data'] || {});
  const trTripsResume = Object.entries(getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUMETR")['data'] || {});
  const requisitionsResumeCounter = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.RESUME")['counter'] || {};
  const ordersResumeCounter = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.ORDERS.RESUME")['counter'] || {};
  const tripsResumeCounter = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUME")['counter'] || {};
  const isOrdersLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.ORDERS.RESUME']?.status?.isFetching || false;
  const isTripsLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUME']?.status?.isFetching || false;
  const isTrTripsLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUMETR']?.status?.isFetching || false;
  const isRequisitionLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.RESUME']?.status?.isFetching || false;
  const isRequisitionCreationLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.CREATIONV2']?.status?.isFetching || false;
  const trTripsResumeCounter = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUMETR")['counter'] || {};
  const ownProducts = getEntityItems(state, "COMPANIES.PRODUCTS") || [];
  const isOwnProductsLoading = state?.api['COMPANIES.PRODUCTS']?.status?.isFetching || false;
  const isProductsLoading = state?.api['COMPANIES.ORDERS.CONTROL.PROVIDERS.PRODUCTSPARAMS']?.status?.isFetching || false;
  const isSignatureValidationLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.TRIPS.VALIDATESIGNATURE']?.status?.isFetching || false;
  const isCodeValidationLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.ORDERS.VALIDATEREQUISITION']?.status?.isFetching || false;

  const providerFacilities = getEntityItems(state, "COMPANIES.ORDERS.CONTROL.PROVIDERS.FACILITIES").map(f => ({
    value: String(f.id),
    label: f.name.concat(' - ', f.alias, ' - ', f.code)
  }));
  const facilityData = getEntityItems(state, "COMPANIES.FACILITIES"); 
  const companyUnits = getEntityItems(state, "COMPANIES.CATALOGS.UNITS");
  const providerProducts = getEntityItems(state, "COMPANIES.ORDERS.CONTROL.PROVIDERS.PRODUCTSPARAMS");
  let companyProviders = getEntityItems(state, "COMPANIES.ORDERS.CONTROL.PROVIDERS").reduce(
    (result, network) => {
      // let id = companyProviders.find(
      //   (provider) => provider.id === network.id
      // );
      if (network.from_companies.id === companyId) {
        if (network.to_companies.type === 2) {
          result.push({
            value: String(network.id),

            label: network.to_companies_alias.concat(
              " - ",
              network.to_companies_code,
              " - ",
              network.to_companies.orbi_id
            ),
            id: network.to_companies.id
          });
        }
      } else {
        if (network.from_companies.type === 2)
          result.push({
            // value: network.id,
            value: String(network.id),
            // label: network.from_companies.corporation_name,
            label: network.from_companies.orbi_id.concat(
              " - ",
              network.from_companies_alias,
              " - ",
              network.from_companies_code
            ),
            id: network.from_companies.id
          });
      }
      return result;
    },
    []
  ) || [];

  companyProviders.unshift(
    {
      value: '-1', // -1 se interpreta como propia
      label: `${state?.globalCatalog?.session?.company?.orbi_id || ''}`,
      id: state?.globalCatalog?.session?.company?.id || -1
    }
  );

  return {
    companyId,
    tripsResume,
    ordersResume,
    requisitionsResume,
    requisitionsResumeCounter,
    ordersResumeCounter,
    tripsResumeCounter,
    isLoading: isRequisitionLoading || isOrdersLoading || isTripsLoading || isTrTripsLoading, 
    providerProducts,
    companyProviders,
    facilityData,
    providerFacilities,
    companyUnits,
    trTripsResumeCounter,
    trTripsResume,
    ownProducts,
    isCO: (state?.globalCatalog?.session?.company?.type?.id || 0) === 2,
    isLoadingProducts: isOwnProductsLoading || isProductsLoading,
    isValidationLoading: isSignatureValidationLoading || isCodeValidationLoading,
    userId: state.globalCatalog.session.user.id,
    isRequisitionCreationLoading
  };
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(downloadPermissionFile());

  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    loadProducts: (params, opt) => dispatch( readEntities("COMPANIES.PRODUCTS", params, opt) ),
    loadCompanyTripsResume: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUME", params, opt)),
    loadCompanyTrTripsResume: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.TRIPS.RESUMETR", params, opt)),
    loadCompanyOrdersResume: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.ORDERS.RESUME", params, opt)),
    loadCompanyRequisitionsResume: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.RESUME", params, opt)),
    loadProviderFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.ORDERS.CONTROL.PROVIDERS.FACILITIES", params, opts)),
    loadFacilityInfo: (params, opt) =>
      dispatch(readEntity("COMPANIES.FACILITIES", params, opt)),
    loadCompanyUnits: (params, opts) =>
      dispatch(readEntities("COMPANIES.CATALOGS.UNITS", params, opts)),
    loadProviderProducts: (params, opts) =>
      dispatch(readEntities("COMPANIES.ORDERS.CONTROL.PROVIDERS.PRODUCTSPARAMS", params, opts)),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.ORDERS.CONTROL.PROVIDERS", params, opts)),
    createRequisition: (params, opts) =>
      dispatch(createEntity("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS", params, opts)),
    createRequisitionV2: (params, opts) =>
      dispatch(createEntity("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.CREATIONV2", params, opts)),
    validateSignatureTrip: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.TRIPS.VALIDATESIGNATURE", params, opt)),
    validateCode: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.ORDERS.VALIDATEREQUISITION", params, opt)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeCoView));

const NoResultsStyled = styled.div`
  text-align: center;
  color: white;
  font-size: 35px;
  margin-top: 80px;
`;

const LoadContentStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;